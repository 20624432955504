@import url('https://fonts.googleapis.com/css?family=Open+Sans|Poiret+One&display=swap');

html {
  font-size: 62.5%;
}

body{
  color:#008000;
  background-color: rgb(41, 38, 38);
  height:1000px;
  font-family: 'Amatic SC', cursive;
  font-weight: bold;
  font-size:25px;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem;
}

a {
  font-family: 'Poiret One', cursive;
  font-size: 1.8rem;
  text-decoration: underline;
  color: black;
}
a:hover {
  color: #008000;
}

h1, h3, h4 {
  color: #1A4704;
}

h3, h4 {
  text-decoration: underline;
}

.dashboard {
  background: url('img/wmp-background.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100%;
}

button:hover {
  cursor: pointer;
}

.delete-btn {
  border-style: none;
  padding: .5rem 1rem;
  background-color: #cb3535;
  color: #eee;
}
.delete-btn:hover {
  background-color: #e00a0a;
}

#root {
  height:100%;
}

.App {
  text-align: center;
  height:100%;
}

*{
  margin:0;
  padding:0;
  box-sizing:border-box;
  scroll-behavior: smooth;
}

form{
  flex-direction: column;
  display:flex;
  width:300px;
  margin-left: 20%;
  padding-top: 100px;
  text-align: center;
}

form span {
margin-left:-87px;
color:rgb(114, 0, 0)
}

.navlinks{
  padding:10px;
  padding-bottom: 3px;
  text-decoration: underline;
  color:#000;
}
.navlinks:hover {
  color: #008000;
}

.logo {
  background: url('img/wmp.jpg') no-repeat center center;
  background-size: contain;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: flex-end;
  margin-right: 1rem;
}

h1{
  padding-bottom: 15px;
  font-size:45px;
}

.span-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: .7;
  border-radius: 3px;
}

.card-img {
  background: url('img/plantCardBG.png') no-repeat center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  border: solid 1px black;
  margin-bottom: 5px;
}
